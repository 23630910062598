import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ErrorActions} from "../actions";
import {tap} from "rxjs";
import {AUTH_PAGE, ERROR} from "../../../models/const/urls";
import {BUTTON_TEXT, GLOBAL_MESSAGES} from "../../../models/const/strings";

@Injectable({providedIn: 'root'})
export class ErrorEffects {
    userAuthError$ = createEffect(() => this.actions$.pipe(
        ofType(ErrorActions.userAuthenticationError),
        tap(() => this.router.navigate([ERROR.NOT_AUTHENTICATED]))
    ), {dispatch: false});

    userAuthExpiredError$ = createEffect(() => this.actions$.pipe(
        ofType(ErrorActions.userAuthenticationExpiredError),
        tap(() => this.router.navigate([ERROR.AUTH_EXPIRED]))
    ), {dispatch: false});

    handleHttpError$ = createEffect(() => this.actions$.pipe(
        ofType(ErrorActions.otherError),
        tap(({error}) => this.snackBar.open(
            error.errors && error.errors.message ? error.errors.message : GLOBAL_MESSAGES.ERROR_HTTP,
            BUTTON_TEXT.CLOSE,
            {
                duration: 5000,
                panelClass: 'error-snackbar',
                verticalPosition: 'top',
            }))
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private router: Router,
        private snackBar: MatSnackBar,
    ) {
    }
}
