import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

    override format(date: Date, displayFormat: any): string {
        const monthName = new Intl.DateTimeFormat('pl-PL', { month: 'long' }).format(date);
        const year = date.getFullYear();
        return monthName + ' ' + year;
    }

    override getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[]{
        return [ 'NI', 'PN' ,'WT', 'ŚR', 'CZ', 'PT', 'SO',];
    }

    override getFirstDayOfWeek(): number {
        return 1; // Monday
    }
}
