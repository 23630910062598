import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EventsActions } from "../actions";
import { EMPTY, of, switchMap } from "rxjs";
import { catchError, map, tap, withLatestFrom } from "rxjs/operators";
import { CALENDAR_EVENTS_INITIAL_DATE_FROM, CALENDAR_EVENTS_INITIAL_DATE_TO, EVENTS_INITIAL_DATE_FROM, EVENTS_INITIAL_DATE_TO } from "../../../../utils/consts/const-dates";
import { EventsService } from "../../../services/events/events.service";
import { EventsFacade } from "../facade/events.facade";
import { ErrorFacade } from "../facade/error.facade";

@Injectable()
export class EventsEffects {
    readonly initEventsDate$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.initEvents),
        switchMap(() => this.eventsService.getEvents(EVENTS_INITIAL_DATE_FROM, EVENTS_INITIAL_DATE_TO).pipe(
            tap(() => {
                this.eventsService.eventsDateFromListBoundary = EVENTS_INITIAL_DATE_FROM;
                this.eventsService.eventsDateToListBoundary = EVENTS_INITIAL_DATE_TO;
            }),
            map((eventsApiResponse) => EventsActions.setEvents({ eventsData: eventsApiResponse.data })),
            catchError((error) => {
                this.eventsService.isEventsLoading = false;
                this.errorFacade.onShowError(error);
                return of(EventsActions.setEvents({ eventsData: [] }));
            })
        ))
    ));

    readonly initCalendarEvents$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.initCalendarEvents),
        switchMap(() => {
            return this.eventsService.getCalendarEvents(CALENDAR_EVENTS_INITIAL_DATE_FROM, CALENDAR_EVENTS_INITIAL_DATE_TO).pipe(
                map(eventsData =>
                    EventsActions.addCalendarEvent({ eventsData: eventsData.data })
                ),
                catchError(error => {
                    this.errorFacade.onShowError(error);
                    return of(EventsActions.addCalendarEvent({ eventsData: [] }));
                })
            );
        })
    ));


    readonly loadAndAddEventsChunk$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.loadAndAddEventsDataChunk),
        withLatestFrom(this.eventFacade.eventsList$),
        switchMap(([action, existingEvents]) => {
            const { dateFrom, dateTo, page } = action;
            return this.eventsService.getEvents(dateFrom, dateTo, page).pipe(
                switchMap(eventsApiResponse => {
                    const combinedEvents = [...existingEvents!, ...eventsApiResponse.data];
                    return [
                        EventsActions.setEvents({ eventsData: combinedEvents }),
                        EventsActions.setEventsPagingMetadata({ metaData: eventsApiResponse.metadata! }),
                    ];
                }),
                catchError(error => {
                    this.eventsService.isEventsLoading = false;
                    this.errorFacade.onShowError(error);
                    return EMPTY;
                })
            );
        })
    ));

    readonly loadAndOverrideAllEventsData$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.loadAndOverrideAllEventsData),
        switchMap((action) => {
            const { dateFrom, dateTo } = action;
            return this.eventsService.getEvents(dateFrom, dateTo).pipe(
                map(eventsData =>
                    EventsActions.setEvents({ eventsData: eventsData.data })
                ),
                catchError(error => {
                    this.eventsService.isEventsLoading = false;
                    this.errorFacade.onShowError(error);
                    return EMPTY;
                })
            );
        })
    ));

    readonly loadCalendarEventsDataChunk$ = createEffect(() => this.actions$.pipe(
        ofType(EventsActions.loadCalendarEventsDataChunk),
        switchMap((action) => {
            const { dateFrom, dateTo } = action;
            return this.eventsService.getCalendarEvents(dateFrom, dateTo).pipe(
                map(eventsData =>
                    EventsActions.addCalendarEvent({ eventsData: eventsData.data })
                ),
                catchError(error => {
                    this.errorFacade.onShowError(error);
                    return EMPTY;
                })
            );
        })
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly eventsService: EventsService,
        private readonly eventFacade: EventsFacade,
        private readonly errorFacade: ErrorFacade,
    ) {
    }
}
